<template>
  <div class="wrapper">
    <div class="password-change inner-wrapper">
      <div class="accounts-form" v-show="!displayed.loading">
        <div class="d-flex df-row">
          <h1
            class="accounts-form__small-bi-header cursor"
            @click="$router.push('/introduction/main')"
          >
            Qpick
          </h1>
          <div class="spacer"></div>
          <button
            class="close-btn"
            v-show="!campaign"
            @click="closeWindow"
          ></button>
        </div>
        <h1
          class="accounts-form__header"
          :class="{
            'password-change__header': !campaign,
            'password-campaign__header': campaign
          }"
        >
          비밀번호 변경
        </h1>
        <p v-show="campaign" class="campaign-explain">
          소중한 개인정보 보호를 위해 비밀번호를 변경해 주세요.
        </p>
        <p v-show="campaign" class="campaign-sub-explain">
          고객님의 개인정보 보호를 위해 비밀번호 변경 캠페인을 진행하고
          있습니다.<br />안전한 개인정보 관리를 위해 새로운 비밀번호로 변경해
          주세요.
        </p>
        <outlinedTextField
          placeholder="현재 비밀번호"
          @value="setOldPassword"
          class="accounts-form__outlined-text"
          type="password"
          maxlength="20"
          ref="oldPassword"
          case="oldPassword"
          @validate="validate"
        ></outlinedTextField>
        <p class="accounts-form__form-error" v-if="error.oldPassword">
          {{ error.oldPassword }}
        </p>
        <outlinedTextField
          placeholder="새로운 비밀번호"
          @value="setPassword"
          class="accounts-form__outlined-text"
          type="password"
          maxlength="20"
          ref="password"
          case="password"
          @validate="validate"
        ></outlinedTextField>
        <p class="accounts-form__form-error" v-if="error.password">
          {{ error.password }}
        </p>
        <outlinedTextField
          placeholder="새로운 비밀번호 확인"
          @value="setPasswordConfirmation"
          class="accounts-form__outlined-text"
          type="password"
          maxlength="20"
          ref="passwordConfirmation"
          case="passwordConfirm"
          @validate="validate"
        ></outlinedTextField>
        <p class="accounts-form__form-error" v-if="error.passwordConfirmation">
          {{ error.passwordConfirmation }}
        </p>
        <button
          :class="[
            oldPassword && password && passwordConfirmation
              ? 'accounts-form__submit'
              : 'accounts-form__submit__disabled'
          ]"
          @click="validateAll"
        >
          변경하기
        </button>
        <button
          v-show="campaign"
          class="accounts-footer__button-transparent next-change"
          @click="nextChange"
        >
          다음에 변경하기
        </button>
      </div>
    </div>
    <toastMsg ref="toast"></toastMsg>
  </div>
</template>

<script>
import Vue from "vue";
import VueCompositionAPI from "@vue/composition-api";
import outlinedTextField from "@/components/accounts/outlined-text-field.vue";
import toastMsg from "@/components/accounts/toast-message.vue";
import {chkValidationPassword} from "@/utils/user.js";
import authAPI from "@/api/accounts/auth.js";
import userAPI from "@/api/accounts/user.js";
import "@/assets/scss/accounts/basis.scss";
import "@vueform/toggle/themes/default.css";
import {createNamespacedHelpers} from "vuex";

const ModuleAlert = createNamespacedHelpers("ModuleAlert");

Vue.use(VueCompositionAPI);

export default {
  data() {
    return {
      user_id: null,
      oldPassword: "",
      password: "",
      passwordConfirmation: "",
      error: {oldPassword: "", password: "", passwordConfirmation: ""},
      checkedOldPassword: false,
      checkedPassword: false,
      checkedPasswordConfirm: false,
      displayed: {loading: true},
      campaign: false,
      callback: ""
    };
  },
  methods: {
    setOldPassword(v) {
      this.oldPassword = v;
    },
    setPassword(v) {
      this.password = v;
    },
    setPasswordConfirmation(v) {
      this.passwordConfirmation = v;
    },
    closeWindow() {
      window.close();
    },
    //비밀번호 검증
    passwordCheck(password, type) {
      userAPI.checkPassword(password).then(response => {
        switch (response.status) {
          case 204:
            if (type == "password") {
              this.error.password =
                "현재 사용중인 비밀번호와 동일합니다. 다시 입력하세요.";
              this.checkedPassword = false;
            } else {
              this.error.oldPassword = "";
              this.checkedOldPassword = true;
            }
            break;

          case 422:
            if (type === "oldPassword") {
              this.error.oldPassword = "현재 비밀번호가 일치하지 않습니다.";
              this.checkedOldPassword = false;
            } else {
              this.error.password = "";
              this.checkedPassword = true;
            }
            break;

          default:
            break;
        }
      });
    },
    //input 유효성 검사
    validate(obj) {
      const _case = obj.case;
      const _value = obj.value;

      if (_case === "oldPassword") {
        if (!this.oldPassword) {
          this.error.oldPassword = "현재 비밀번호를 입력해주세요.";
          this.checkedOldPassword = false;
        } else {
          this.passwordCheck(this.oldPassword, "oldPassword");
        }
      }

      if (_case === "password") {
        let resPassword = chkValidationPassword(_value, "새로운 ");

        if (!resPassword.result) {
          this.error.password = resPassword.msg;
          this.checkedPassword = false;
        } else {
          this.passwordCheck(this.password, "password");
        }
      }

      if (_case === "passwordConfirm") {
        if (!_value) {
          this.error.passwordConfirmation =
            "새로운 비밀번호 확인을 입력해주세요.";
          this.checkedPasswordConfirm = false;
        } else if (this.password !== _value) {
          this.error.passwordConfirmation = "비밀번호가 일치하지 않습니다.";
          this.checkedPasswordConfirm = false;
        } else {
          this.error.passwordConfirmation = "";
          this.checkedPasswordConfirm = true;
        }
      }
    },
    //변경하기 버튼 눌렀을때 모든 입력창 유효성 검사
    validateAll() {
      if (!this.oldPassword || !this.password || !this.passwordConfirmation)
        return false;

      const inputs = [
        {case: "oldPassword", value: this.oldPassword},
        {case: "password", value: this.password},
        {case: "passwordConfirm", value: this.passwordConfirmation}
      ];

      inputs.forEach(item => {
        this.validate(item);
      });

      //모든 필수값 유효성 검사 성공시
      if (
        this.checkedPassword &&
        this.checkedPasswordConfirm &&
        this.checkedOldPassword
      )
        this.submit();
    },
    //비밀번호 변경
    submit() {
      userAPI
        .changePassword(
          this.oldPassword,
          this.password,
          this.passwordConfirmation
        )
        .then(res => {
          if (res.status == 204) {
            this.$store.dispatch("ModuleUser/actUserLog", {
              eventName: "비밀번호 변경",
              requestLocation: "큐픽 > 계정정보 > 비밀번호 변경"
            });
            this.setAlert({
              isVisible: true,
              message: "비밀번호가 변경되었습니다.",
              event: "callback"
            });
          } else if (
            res.data.errors[0].userCode == "user.password.incorrect.user"
          ) {
            this.$refs.toast.show("비밀번호가 맞지 않습니다.");
          } else {
            this.$refs.toast.show(res.data.errors[0].userMsg);
          }
        });
    },
    //다음에 변경하기 버튼 선택시 (& 비밀번호 변경완료 후 콜백처리)
    nextChange() {
      !this.callback
        ? this.$router.replace("/admin/dashboard")
        : this.callback.match(/^http(s*):\/\//)
        ? (location.href = this.callback)
        : this.$router.replace(this.callback);
    },
    ...ModuleAlert.mapMutations(["setAlert"])
  },
  created() {
    //비밀번호 변경 캠페인 케이스 진입여부
    this.campaign = this.$route.query.campaign === "y" ? true : false;

    //콜백 파라미터 여부
    if (this.$route.query.callback) {
      this.callback = this.$route.query.callback;
    }

    this.$EventBus.$on("alertCancel", event => {
      switch (event) {
        case "back":
          this.$router.go(-1);
          break;

        case "not_allowed":
          this.$router.push("/admin/dashboard");
          break;

        case "login:replace":
          this.$router.replace("/accounts/login");
          break;

        case "login:push":
          this.$router.push("/accounts/login");
          break;

        case "callback":
          this.nextChange();
          break;
      }
    });
  },
  mounted() {
    authAPI.logged().then(res => {
      switch (res.status) {
        case 200:
          if (res.data.grade < 1) {
            this.setAlert({
              isVisible: true,
              message: "접근 권한이 없습니다.",
              event: "not_allowed"
            });
          } else {
            this.user_id = res.data.id;
            this.displayed.loading = false;
          }
          break;

        default:
          this.setAlert({
            isVisible: true,
            message: res.data.errors[0].userMsg,
            event: "back"
          });
          break;
      }
    });
  },
  components: {
    outlinedTextField,
    toastMsg
  }
};
</script>

<style>
.password-change__header {
  margin-bottom: 100px;
}
.password-campaign__header {
  margin-bottom: 16px;
}
.next-change {
  margin: 16px 0;
}
.campaign-explain {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.005em;
  text-align: left;
  color: #484948;
  margin-bottom: 8px;
}
.campaign-sub-explain {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.005em;
  text-align: left;
  color: #8d8d8d;
  margin-bottom: 32px;
}
@media screen and (max-width: 767px) {
  .close-btn {
    width: fit-content;
    height: fit-content;
    padding-top: 27px;
    font-size: 0;
  }
  .close-btn::after {
    content: "×";
    font-size: 24px;
  }
}
</style>
